<template>
  <div>
    <div class="header">
      <div class="left" @click="goBack">
        <el-icon><ArrowLeftBold /></el-icon>
      </div>
      <div v-if="type == 'availability'" style="font-weight: 600">
        {{ title }}
        <span style="color: red;font-size: 12px" v-if="weekList.length > 0">
          (GMT{{availabilityOffsetTime}})
        </span>
        <span style="color: red;font-size: 12px" v-else> (GMT{{ timeOffsetCom }}) </span>
      </div>
      <div v-else-if="type == 'Disable'" style="font-weight: 600">
        {{ title }}
        <span style="color: red;font-size: 12px" v-if="unavailableList.length > 0">
          (GMT{{unavailabilityTitle}})
        </span>
        <span style="color: red;font-size: 12px" v-else> (GMT{{ timeOffsetCom }}) </span>
      </div>
      <div v-else style="font-weight: 600">{{ title }}</div>
      <div style="width: 40px"></div>
    </div>
    <!-- 1 -->
    <div v-if="type == 'availability'" class="container">
      <!-- <div>
        <h3>Set availability</h3>
        <div class="title">
          Set the hours when you are available for business appointments
        </div>
      </div>
      <el-divider class="hr" border-style="dashed" /> -->
      <div class="weeks">
        <div class="weekItem" v-for="(info, ind) in weekList" :key="ind">
          <el-row
            :gutter="24"
            class="row"
            v-for="(item, index) in info.sopList"
            :key="index"
          >
            <el-col :span="4"
              ><div class="weekName" v-if="index == 0">
                <el-checkbox
                  @change="changBox(info, ind)"
                  v-model="item.enabled"
                  size="large"
                /><span style="vertical-align: 1.5px; margin-left: 8px">
                  {{ item.name.slice(0, 3) }}</span
                >
              </div>
            </el-col>
            <el-col v-if="item.enabled" :span="7">
              <div class="col">
                <el-time-select
                  prefix-icon
                  v-model="item.timeStart"
                  :max-time="item.timeEnd"
                  class="select"
                  placeholder="Start"
                  start="00:00"
                  step="01:00"
                  end="24:00"
                  :editable="false"
                  clear-icon
                  readonly
                /></div
            ></el-col>
            <!-- <el-col :span="1"><div style="text-align: center">-</div></el-col> -->
            <el-col v-if="item.enabled" :span="7"
              ><div class="col">
                <el-time-select
                  prefix-icon
                  v-model="item.timeEnd"
                  :disabled="!item.timeStart"
                  class="select"
                  :min-time="item.timeStart"
                  placeholder="End"
                  start="00:00"
                  step="01:00"
                  end="24:00"
                  :editable="false"
                  clear-icon
                  readonly
                /></div
            ></el-col>

            <el-col v-if="item.enabled" :span="6"
              ><div>
                <el-button
                  v-if="info.sopList.length !== 1"
                  @click="deleteItem(ind, index)"
                  :disabled="info.sopList.length == 1"
                  :icon="Delete"
                  style="color: #000000; border: 1px solid #837d7d"
                  circle
                />
                <el-button
                  v-if="index == weekList[ind].sopList.length - 1"
                  @click="addItem(ind)"
                  style="
                    color: #000000;
                    border: 1px solid #409eff;
                    background-color: rgba(64, 158, 255, 1);
                    float: right;
                    margin-top: 8px;
                  "
                  :icon="Plus"
                  circle
                /></div
            ></el-col>
            <el-col
              style="color: #606266; font-weight: 700"
              v-if="!item.enabled && index == 0"
              :span="5"
              ><div>Unavailable</div></el-col
            >
          </el-row>
        </div>
      </div>
      <div style="float: right; margin-top: 20px">
        <el-button
          style="background: #000000; color: #ffffff; font-size: 10px; width: 70px"
          round
          @click="handleOk"
        >
          SAVE
        </el-button>
      </div>
    </div>

    <!-- 2 -->
    <div v-if="type == 'Define'">
      <SchedulerMobile></SchedulerMobile>
    </div>

    <!-- 3 -->
    <div v-if="type == 'Disable'">
      <div style="display: flex; justify-content: space-around; margin-top: 20px">
        <el-date-picker v-model="DateTime1" type="date" placeholder="Select StartTime" />
        <el-date-picker v-model="DateTime2" type="date" placeholder="Select EndDate" />
        <el-button :icon="Plus" circle @click="changeSelectValue" />
      </div>

      <!-- <el-icon><Plus /></el-icon> -->

      <div class="disableDialog">
        <!-- <el-date-picker
          v-model="disableValue"
          type="daterange"
          start-placeholder="Start date"
          end-placeholder="End date"
          @change="changeSelectValue"
        /> -->

        <div class="ul">
          <el-row class="li">
            <el-col :span="3"
              ><div class="grid-content ep-bg-purple" />
              Id
            </el-col>
            <el-col :span="7"
              ><div class="grid-content ep-bg-purple-light" />
              StartDate
            </el-col>
            <el-col :span="7"
              ><div class="grid-content ep-bg-purple" />
              EndDate</el-col
            >
            <el-col :span="7"
              ><div class="grid-content ep-bg-purple-light" />
              Controls</el-col
            >
          </el-row>

          <el-row class="li" v-for="(item, index) in unavailableList" :key="index">
            <el-col :span="3"
              ><div class="grid-content ep-bg-purple" />
              {{ index + 1 }}
            </el-col>
            <el-col :span="7"
              ><div class="grid-content ep-bg-purple-light" />
              <el-tag v-if="item.id" type="success">{{ item.dateStart }}</el-tag>
              <el-tag v-else type="primary">{{ item.dateStart }}</el-tag>
            </el-col>
            <el-col :span="7"
              ><div class="grid-content ep-bg-purple" />
              <el-tag v-if="item.id" type="success">{{ item.dateEnd }}</el-tag>
              <el-tag v-else type="primary">{{ item.dateEnd }}</el-tag>
            </el-col>
            <el-col :span="7"
              ><div class="grid-content ep-bg-purple-light" />
              <el-button
                @click="deleteTag(item, index)"
                style="
                  color: #000000;
                  border: 1px solid #837d7d;
                  width: 28px;
                  height: 28px;
                "
                :icon="Delete"
                circle
            /></el-col>
          </el-row>
        </div>
      </div>
      <div v-if="unavailableList.length > 0" style="float: right; margin: 20px">
        <el-button
          style="background: #000000; color: #ffffff; font-size: 10px; width: 70px"
          round
          @click="disabledSubmit"
        >
          SAVE
        </el-button>
      </div>
    </div>

    <div v-if="type == 'list'">
      <el-tabs stretch v-model="activeName" class="demo-tabs" @tab-click="handleClick">
        <el-tab-pane label="Future" name="first">
          <div class="mobile-list">
            <div v-for="item in reserveList" :key="item.id" class="list-item">
              <div class="item-thumbnail">
                <el-avatar
                  v-if="item.clientAvatar"
                  shape="square"
                  :size="100"
                  :fit="fit"
                  :src="ImageUrl + item.clientAvatar"
                />
                <img
                  style="height: 100px; width: 100px; border-radius: 6%"
                  v-else
                  src="../../assets/default-user.png"
                  alt=""
                />
              </div>
              <div class="item-details">
                <div style="display: flex; justify-content: space-between">
                  <h3 class="item-title">Username: {{ item.clientName }}</h3>
                  <div>
                    <!-- <el-icon @click="deleteCard"><Close /></el-icon> -->
                  </div>
                </div>
                <p class="item-description">User mailbox: {{ item.clientEmail }}</p>
                <p class="item-description">Appointment date:<span style="color:red;font-size: 12px;">(GMT{{timeOffsetCom}})</span> {{ item.date }}</p>
                <p class="item-description">
                  CreateTime:<span style="color: #800080">{{ item.updateTime }}</span>
                </p>
                <p class="item-description">
                  Time:
                  <el-tag type="primary">{{ item.timeStart }}-{{ item.timeEnd }}</el-tag>
                </p>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="History" name="second">
          <div class="mobile-list">
            <div v-for="item in reserveList" :key="item.id" class="list-item">
              <div class="item-thumbnail">
                <el-avatar
                  v-if="item.clientAvatar"
                  shape="square"
                  :size="100"
                  :fit="fit"
                  :src="ImageUrl + item.clientAvatar"
                />
                <img
                  style="height: 100px; width: 100px; border-radius: 6%"
                  v-else
                  src="../../assets/default-user.png"
                  alt=""
                />
              </div>
              <div class="item-details">
                <div style="display: flex; justify-content: space-between">
                  <h3 class="item-title">Username: {{ item.clientName }}</h3>
                  <div>
                    <!-- <el-icon @click="deleteCard"><Close /></el-icon> -->
                  </div>
                </div>
                <p class="item-description">User mailbox: {{ item.clientEmail }}</p>
                <p class="item-description">Appointment date:<span style="color:red;font-size: 12px;">(GMT{{timeOffsetCom}})</span> {{ item.date }}</p>
                <p class="item-description">
                  CreateTime:<span style="color: #800080">{{ item.updateTime }}</span>
                </p>
                <p class="item-description">
                  Time:
                  <el-tag type="primary">{{ item.timeStart }}-{{ item.timeEnd }}</el-tag>
                </p>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div class="paging">
        <el-pagination
          small
          :total="config.total"
          :page="paramsList.pageNum"
          :limit="paramsList.pageSize"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="prev, pager, next"
          :pager-count="8"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import { ElMessage, ElMessageBox } from "element-plus";
import { useRoute, useRouter } from "vue-router";
import SchedulerMobile from "@/components/calendar/Scheduler_mobile.vue";
import {
  getWeekTemplate,
  setWeekTemplate,
  getUnavailableList,
  setUnavailableList,
  getHistoryApptPage,
  getReserveApptPage,
} from "@/api/detailPage.js";
import _ from "lodash";
import {
  getOnlinePeriod,
  setOnlinePeriod,
  deleteonlinePeriod,
  getOnlinePeriodPage,
} from "@/api/detailPage.js";
import {
  Check,
  Delete,
  Edit,
  Message,
  Search,
  Star,
  Upload,
  Download,
  Plus,
  ArrowLeftBold,
  WarnTriangleFilled,
  More,
  Close,
} from "@element-plus/icons-vue";
import {
  checkTimeConflicts,
  addOneMonthAndSetToFirstDay,
  checkTimeConflicts1,
  nowDay,
  selectDay,
  checkOverlap,
  formatDateTime,
} from "@/components/timeTransform/index.js";
const ImageUrl = process.env.VUE_APP_BASE_ImageUrl;
const route = useRoute();
const router = useRouter();
const type = ref("");
const title = ref("");
const tZoneOffset = computed(() => {
  let time = new Date().getTimezoneOffset();
  return 0 - time / 60;
});
const store = useStore();
const expertId = computed(() => {
  return JSON.parse(store.state.userInfo).id;
});
const queryParams = ref({
  pageNum: "1",
  pageSize: "10",
});
const disableValue = ref("");
const unavailableList = ref([]);
const DateTime1 = ref("");
const DateTime2 = ref("");
const state = reactive({
  total: 100,
});

const reserveList = ref([]);

const config = ref({
  total: 0,
});

const paramsList = ref({
  expertId: expertId.value,
  timeZone: tZoneOffset.value,
  pageNum: 1,
  pageSize: 10,
});
const activeName = ref("first");

// 1 参数
const weekQuery = ref({
  expertId: expertId.value,
  weekOrder: 1,
});

// 区域一
const weekList = ref([]);



const unavailabilityTitle = computed(() => {
  let val = unavailableList.value[0].timeZone
    ? unavailableList.value[0].timeZone > 0
      ? `+${unavailableList.value[0].timeZone}`
      : unavailableList.value[0].timeZone
    : tZoneOffset.value;
  return val;
});

const availabilityOffsetTime = computed(()=>{
  let val = weekList.value[0].timeZone
    ? weekList.value[0].timeZone > 0
      ? `+${weekList.value[0].timeZone}`
      : weekList.value[0].timeZone
    : tZoneOffset.value;
  return val;
})

//关于时区正负性的渲染
const timeOffsetCom = computed(() => {
  let val = tZoneOffset.value > 0 ? `+${tZoneOffset.value}` : tZoneOffset.value;
  return val;
});

const addItem = (ind) => {
  console.log(ind, "ind");
  // return;
  weekList.value[ind].sopList.push({
    expertId: expertId.value,
    name: weekList.value[ind].sopList[0].name,
    enabled: weekList.value[ind].sopList[0].enabled,
    timeStart: "",
    timeEnd: "",
    timeZone: tZoneOffset.value,
    published: false,
    published_ui: false,
  });
  console.log(weekList.value, "weekList.value");
};
const deleteItem = (ind, index) => {
  const parent = weekList.value[ind];
  // const dataIndex = parent.sopList.findIndex((item) => item.Uid === Uid);

  // if (dataIndex !== -1) {
  parent.sopList.splice(index, 1);

  console.log(weekList.value, "weekList.value");
};

const handleOk = async () => {
  let Switch = weekList.value.every((x) => !x.enabled);
  if (weekList.value.length > 0 && !Switch) {
    let arr = [];
    weekList.value.forEach((item) => {
      if (!item.enabled) {
      } else {
        item.sopList.forEach((info) => {
          arr.push(info);
        });
      }
    });
    console.log(arr, "arr");
    let isSuccess = checkTimeConflicts1(arr);
    if (isSuccess) {
      console.log("成功", weekList.value);

      let query = _.cloneDeep(weekList.value);
      console.log(query, "query");
      let arr = [];
      query.forEach((info) => {
        if (!info.enabled) {
          const filteredSopList = info.sopList.filter((x) => x.timeStart && x.timeEnd);
          info.sopList = filteredSopList;
        }
        arr.push(info);
      });

      console.log(arr, "arr");

      arr = arr.map((item, index) => ({
        ...item,
        expertId: expertId.value,
        timeZone: tZoneOffset.value,
        sopList: item.sopList.map((i) => ({
          ...i,
          parentId: arr[index].id,
          timeStart: i.timeStart + ":00",
          timeEnd: i.timeEnd === "00:00" ? "24:00:00" : i.timeEnd + ":00",
          expertId: expertId.value,
          timeZone: tZoneOffset.value,
        })),
      }));

      console.log(arr, "提交的数组");
      let res = await setWeekTemplate(arr);
      console.log(res, "res");
      if (res.code == "200") {
        ElMessage({
          showClose: true,
          message: "Save successfully.",
          type: "success",
        });
        getWeekList();
      }
    } else {
      console.log("失败");
      ElMessage({
        showClose: true,
        message: "The time period you set does not meet the specification!",
        type: "error",
      });
    }
  } else {
    ElMessage({
      showClose: true,
      message: "Please set a date for at least one period!",
      type: "warning",
    });
  }
};

const changBox = (item, index) => {
  console.log(item, "item");
  console.log(index, "index");
  weekList.value[index].enabled = !weekList.value[index].enabled;

  const { enabled } = item.sopList[0];

  for (let i = 1; i < item.sopList.length; i++) {
    item.sopList[i].enabled = enabled;
  }
  console.log(weekList.value, "weekList.value");
};

//返回上页
const goBack = () => {
  router.push("/profileInfo/reservation");
};

const changeSelectValue = (e) => {
  if (!DateTime2.value || !DateTime2.value) {
    ElMessage({
      type: "warning",
      message: "Please select the date first!",
    });
    return;
  }
  if (DateTime2.value < DateTime1.value) {
    ElMessage({
      type: "error",
      message: "The end date cannot be less than the start date",
    });
    return;
  }
  if (DateTime2.value < new Date(new Date().getTime() - 24 * 60 * 60 * 1000)) {
    ElMessage({
      type: "error",
      message: "The set date cannot be younger than the current date",
    });
    return;
  }
  const arr = {
    dateStart: selectDay(DateTime1.value),
    dateEnd: selectDay(DateTime2.value),
    delFlag: false,
    expertId: expertId.value,
    timeZone: tZoneOffset.value,
  };

  unavailableList.value.push(arr);
  // setTimeout(() => {
  //   disableValue.value = "";
  // }, 500);
};

const deleteTag = (item, index) => {
  if (item.id) {
    ElMessageBox.confirm(
      `Are you sure you want to delete <span style="color:#10bd8e;">${item.dateStart}</span> to  <span style="color:red;">${item.dateEnd}</span> ?`,
      "Warning",
      {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        dangerouslyUseHTMLString: true,
      }
    )
      .then(async () => {
        let data = [
          {
            ...item,
            delFlag: true,
          },
        ];
        let res = await setUnavailableList(data);
        if (res.code == 200) {
          getUnavailable();
          ElMessage({
            type: "success",
            message: "Delete completed",
          });
        }
      })
      .catch(() => {
        ElMessage({
          type: "info",
          message: "Delete canceled",
        });
      });
  } else {
    unavailableList.value.splice(index, 1);
  }
};

const disabledSubmit = async () => {
  if (unavailableList.value.length === 0) {
    ElMessage({
      type: "error",
      message: "The period cannot be empty",
    });
    return;
  }
  let x = checkOverlap(unavailableList.value);
  if (x && unavailableList.value.length > 0) {
    console.log("通过");
    let data = _.cloneDeep(unavailableList.value);
    data = data.map((x) => {
      return {
        ...x,
        timeZone: tZoneOffset.value,
      };
    });
    console.log(data, "data");
    // return;
    let res = await setUnavailableList(data);
    if (res.code == 200) {
      getUnavailable();
      ElMessage({
        type: "success",
        message: "Save successfully.",
      });
    }
  } else {
    ElMessage({
      type: "error",
      message: "The set period is invalid",
    });
  }
};

const getWeekList = async () => {
  let res = await getWeekTemplate(weekQuery.value);
  if (res && res.code == "200") {
    //         timeStart: info.timeStart.slice(0, 5),
    //         timeEnd: info.timeEnd.slice(0, 5),
    weekList.value = res.data.map((x) => {
      if (x.sopList.length === 0) {
        // 如果 sopList 为空，则创建一个包含 tag 的对象
        return {
          ...x,
          sopList: [
            {
              name: x.tag,
              enabled: x.enabled,
              timeStart: "",
              timeEnd: "",
              expertId: expertId.value,
              timeZone: tZoneOffset.value,
            },
          ],
        };
      } else {
        // 否则，将每个 sopList 元素的 name 设置为 tag，并返回更新后的对象
        return {
          ...x,
          sopList: x.sopList.map((item) => {
            return {
              ...item,
              name: x.tag,
              enabled: x.enabled,
              timeStart: item.timeStart.slice(0, 5),
              timeEnd: item.timeEnd.slice(0, 5),
            };
          }),
        };
      }
    });
  }
  console.log(weekList.value, "weekList.value");
};
const getUnavailable = async () => {
  let data = {
    expertId: expertId.value,
    validDate: true,
  };
  let res = await getUnavailableList(data);
  if (res.code == 200) {
    unavailableList.value = res.data.map((item) => {
      return {
        ...item,
        dateStart: item.dateStart.slice(0, 10),
        dateEnd: item.dateEnd.slice(0, 10),
      };
    });
  }
  console.log(res, "res");
};

const deleteCard = () => {
  ElMessageBox.confirm("Are you sure you want to delete this record?", "Warning", {
    confirmButtonText: "OK",
    cancelButtonText: "Cancel",
    type: "warning",
  }).then(() => {
    ElMessage({
      type: "success",
      message: "Successfully deleted",
    });
  });
};

const handleSizeChange = (val) => {
  console.log(val, "页");
  paramsList.value.pageNum = val;
  getAppointmentList();
};
const handleCurrentChange = (val) => {
  console.log(val, "分");
  paramsList.value.pageNum = val;
  getAppointmentList();
};

//获取历史预约列表
const getAppointmentList = async () => {
  const { code, rows, total } = await getHistoryApptPage(paramsList.value);
  if (code == 200 && rows.length > 0) {
    config.value.total = total;
    reserveList.value = rows.map((item) => {
      return {
        ...item,
        date: item.date.slice(0, 10),
        timeStart: item.timeStart.slice(0, 5),
        timeEnd: item.timeEnd.slice(0, 5),
        updateTime: formatDateTime(item.updateTime),
      };
    });
    console.log(rows, "rows");
  }
};
//获取未来预约列表
const getReserveApptPageList = async () => {
  const { code, rows, total } = await getReserveApptPage(paramsList.value);
  if (code == 200 && rows.length > 0) {
    config.value.total = total;
    reserveList.value = rows.map((item) => {
      return {
        ...item,
        date: item.date.slice(0, 10),
        timeStart: item.timeStart.slice(0, 5),
        timeEnd: item.timeEnd.slice(0, 5),
        updateTime: formatDateTime(item.updateTime),
      };
    });
    console.log(rows, "rows");
  }
};

const handleClick = (tab, e) => {
  paramsList.value.pageNum = "1";
  reserveList.value = [];
  console.log(tab.props.label);
  if (tab.props.label == "History") {
    title.value = "View History";
    getAppointmentList();
  } else {
    title.value = "View Future";
    getReserveApptPageList();
  }
  console.log(e);
};
onMounted(() => {
  type.value = route.query.type;
  if (type.value == "availability") {
    getWeekList();
    title.value = "Set Availability";
  } else if (type.value == "Disable") {
    getUnavailable();
    title.value = "Set UnAvailability";
  } else if (type.value == "Define") {
    title.value = "Set date";
  } else if (type.value == "list") {
    // getAppointmentList();
    getReserveApptPageList();
    title.value = "View Future";
  }
});
</script>

<style lang="scss" scoped>
// :deep(.el-select__input) {
//   width: 50px !important;
// }
:deep(.el-date-editor) {
  padding: 0;
  width: 150px !important;
}

:deep(.el-input__inner) {
  width: 80% !important;
  // overflow: hidden !important;
  // background: red;
}

:deep(.el-col) {
  padding: 0 5px !important;
}

.disableDialog {
  height: 500px;
  // background: #a2afb9;
  text-align: center;
  border-radius: 12px;
  padding: 20px;
  box-sizing: border-box;
  overflow-x: hidden;
  .ul {
    height: 420px;
    overflow-y: auto;
    overflow-x: hidden;
        border: 1px #eee solid;
    .li {
      display: flex;
      justify-content: space-between;
      width: 380px;
      margin: 10px auto;
      line-height: 25px;

      .id {
        width: 20px;
        text-align: right;
      }

      .li-icon {
        vertical-align: -2.5px;
        cursor: pointer;
      }
    }
  }
}

.header {
  height: 40px;
  padding-left: 10px;
  line-height: 40px;
  width: 100%;
  background-color: #e5e5e5;
  color: #000000;
  display: flex;
  justify-content: space-between;
}

.container {
  background: #ffffff;
  padding: 3%;
  box-sizing: border-box;
  overflow-x: hidden;
  .title {
    margin: 10px 0;
  }
  .weeks {
    overflow-y: auto;
    overflow-x: hidden;
    height: 700px;
    .weekItem {
      // box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
      // height: 100px;
      border-bottom: 1px solid #9f9f9f;
      line-height: 45px;
      // margin-bottom: 10px;
      padding: 5px 10px;
      box-sizing:border-box
      

      // .select {
      //   width: 85%;
      // }
      .row {
        margin: 5px 0px;
        border-bottom: none;
        box-sizing: border-box;
      }
      .weekName {
        font-weight: bold;
        font-size: 13px !important;
      }
    }
  }
  .btn_footer {
    margin-top: 20px;
  }
}

.mobile-list {
  overflow-y: auto;
  height: 70vh;
  font-family: Arial, sans-serif;
  .list-item {
    display: flex;
    padding: 10px;
    border-bottom: 1px solid #eee;
    box-sizing: border-box;
    .item-details {
      padding: 3px;
      flex: 1;
      .item-title {
        margin-top: 0;
        // margin-bottom: 5px;
        font-size: 16px;
        color: #333;
        white-space: nowrap; /* 禁止文本换行 */
        overflow: hidden; /* 隐藏溢出的文本 */
        text-overflow: ellipsis; /* 显示省略号 */
        width:250px;
      }

      .item-description {
        // margin: 2px 0px;
        line-height: 20px;
        font-size: 14px;
        color: #666;
      }
    }

    .item-thumbnail {
      // flex: 0 0 80px;
      margin-right: 10px;
      width: 100px;
      height: 100px;

      .item-thumbnail img {
        width: 80px;
        height: 80px;

        height: auto;
      }
    }
  }
}
.paging {
  float: right;
  margin: 20px;
}
</style>
